import { ErrorMessage } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./error.scss"

interface ErrorMessageProps {
    message?: string;
    testId?: string;
    name?: string;
}

const Error: FC<ErrorMessageProps> = ({ message, testId, name }) => {
    const {t} = useTranslation()
    return (
        <div className="input__error" data-testid={testId}>
            { name==="phoneNumber" ? t("rise-phone-validation") : name==="email" ? t("rise-email-validation") : t("rise-name-validation") }
        </div>
    )
}

export default Error;