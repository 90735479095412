import { Divider } from 'antd';
import './footer.scss'
import React, { FC } from 'react';
import facebook from "../../../assets/images/facebook-icon.svg"
import instagram from "../../../assets/images/instagram-icon.svg"
import whatsapp from "../../../assets/images/whatsapp-icon.svg"
import linkedin from "../../../assets/images/linkedin-icon.svg"
import logo from "../../../assets/images/logo-with-hummingbird.svg"
import Navbar from '../../../shared/components/NavBar';
import { openFacebook, openInstagram, openLinkedIn } from '../../../shared/utils/openApppStore';

const FooterComponent: FC = () => {
    const sections = [
        { id: 'home', label: 'Home' },
        { id: 'numbers', label: 'Numbers' },
        { id: 'partners', label: 'Partners' },
        { id: 'how-it-works', label: 'How it works' },
        { id: 'about-us', label: 'About us' },
        { id: 'faq', label: 'FAQ' },
        ];

        const isMobile = window.innerWidth < 480
  return (
    <div className="footer">
        {!isMobile && 
            <>
                <img src={logo} alt="Logo" className="logo" />
                <Divider className='divider' />
            </>
        }
        <div className={'tnc'}>
            {isMobile && <img src={logo} alt="Logo" className="logo" />}
            {!isMobile && <div>All rights reserved ® Rise egypt  | Terms and conditions apply!</div>}
            <div className='icon-group'>
                <img className='quick-link' src={facebook} alt="facebook" onClick={openFacebook}/>
                <img className='quick-link' src={instagram} alt="instagram" onClick={openInstagram}/>
                <img className='quick-link' src={linkedin} alt="linkedin" onClick={openLinkedIn}/>
            </div>
        </div>
        {isMobile && <div className='text'>All rights reserved ® Rise egypt  | Terms and conditions apply!</div>}
    </div>
  );
};

export default FooterComponent;
