import i18next from 'i18next'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { getCookies } from '../../utils/cookiesHelper'
import { getDeviceType } from '../../utils/getWindowSize'
import { fbOneLink, instaOneLink } from '../../utils/openApppStore'
import ButtonComponent from '../ButtonComponent'
import bannerMobile from "../../../assets/images/banner-mobile.webp"
import bannerMobileArabic from "../../../assets/images/banner-mobile-arabic.webp"
import bannerDesktop from "../../../assets/images/banner-desktop.webp"
import bannerDesktopArabic from "../../../assets/images/banner-desktop-arabic.webp"
import "./bannerimagecomponent.scss"

interface BannerImageComponentProps { 
}

const BannerImageComponent: FC<BannerImageComponentProps> = () => {
    const { t } = useTranslation();
    const language = getCookies().i18next || i18next.language;
    const deviceType = getDeviceType();
    const isArabic = language == "ar" && 'arabic';
    const isTabletOrDesktop = deviceType === 'desktop' || deviceType === 'tablet';
    const isMobile = deviceType === 'mobile'
    const location = useLocation();
    const isFB = location.pathname.includes('samsung-fb')
    const isInsta = location.pathname.includes('samsung-insta')


    return (
        <>
            <div className={`bannerimagecomponent ${isArabic}`}>
                {isTabletOrDesktop && 
                <div className={`wrapper  ${isArabic}`}>
                    <img className='banner-image' src={isArabic ? bannerDesktopArabic : bannerDesktop} alt="samsung-banner" />
                    <div className='download'>
                    <ButtonComponent size={deviceType === 'desktop' ? 'x-large' : 'large'} onClick={isFB ? fbOneLink : isInsta ? instaOneLink : fbOneLink}>{t('download_now')}</ButtonComponent>
                    </div>
                </div>}
                {isMobile &&
                    <div className={`wrapper  ${isArabic}`}>
                    <img className='banner-image' src={isArabic ? bannerMobileArabic : bannerMobile} alt="samsung-banner" />
                    <div className='mobile-download'>
                    <ButtonComponent size='wide' onClick={isFB ? fbOneLink : isInsta ? instaOneLink : fbOneLink}>{t('download_now')}</ButtonComponent>
                    </div>
                    </div>
                }
            </div>
        </>
    )
}

export default BannerImageComponent