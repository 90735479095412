import React, { FC } from 'react';
import hummingbird from "../../../assets/images/humming-bird-right.svg"
import playstore from "../../../assets/images/google-play.svg"
import appstore from "../../../assets/images/app-store.svg"
import retailers from "../../../assets/images/retailers.svg"
import usersApprove from "../../../assets/images/users-approve.svg"
import returns from "../../../assets/images/returns.svg"
import sparkle from "../../../assets/images/sparkle.svg"
import './numbers.scss';
import MetricsCardComponent from '../../../shared/components/MetricsCardComponent';
import { Row, Col } from 'antd';
import CarouselComponent from '../../../shared/components/CarouselComponent';
import { openAppStore, openGooglePlaystore } from '../../../shared/utils/openApppStore';
import { getOS } from '../../../shared/utils/getOSDetail';

const Numbers: FC = () => {
    const os = getOS();
    const isDesktop = os === 'Windows' || os === 'MacOS' || os === 'UNIX' || os === 'Linux';
  return (
    <div id='numbers' className="numbers">
        <div><img src={hummingbird} alt="humming-bird" /></div>
        <div className='header'>
            <div className='users'>Thousands of users already embrace the</div>
            <div className='experience'>Rise app experience</div>
            <div className='description'>Join thousands already achieving their financial goals with Rise. Simplify your finances and unlock new possibilities. Take control of your money today with Rise.</div>
        </div>
        <div className='downloads'>
        {(os === 'Android' || isDesktop) && <img src={playstore} onClick={openGooglePlaystore} alt="google-play-icon" className='google-play' />}
          {(os === 'iOS' || isDesktop) && <img src={appstore} onClick={openAppStore} alt="app-store-icon" />}
        </div>
        <div className='rise-in-numbers'>
            <div>
                <div className='label'>Rise in <span className='sub-label'>numbers</span></div>
            </div>
            <div className='description'>The Rise app is rapidly growing and reaching more happy users every day</div>
                    
            <Row className='metric-card-container'>
                <Col xs={12} sm={12} md={6} className="metric-card">
                    <MetricsCardComponent count="+50K" label="Applications submitted" imgSrc={sparkle} />
                </Col>
                <Col xs={12} sm={12} md={6} className="metric-card">
                    <MetricsCardComponent count="+100" label="Retail partners" imgSrc={retailers} />
                </Col>
                <Col xs={12} sm={12} md={6} className="metric-card">
                    <MetricsCardComponent count="3 min" label="Average time to get approved" imgSrc={usersApprove} />
                </Col>
                <Col xs={12} sm={12} md={6} className="metric-card">
                    <MetricsCardComponent count="49%" label="Returning customers"  imgSrc={returns} />
                </Col>
            </Row>
        </div>
        <div className='user-story'>Rise with love: <span className='subtitle'>our user stories</span></div>
        <CarouselComponent />
    </div>
  );
};

export default Numbers;
