import { Row, Col } from 'antd';
import React, { FC } from 'react';
import hummingBird from "../../../assets/images/Humminbird.png"
import './aboutUs.scss';

const AboutUs: FC = () => {
  return (
    <div id='about-us' className="about-us">
        <div className='header'>About us</div>
        <Row gutter={[16, 16]} className="description-tiles">
            <Col xs={{ span: 24, order: 1 }} md={{ span: 13, order: 1 }}>
                <div className="our-mission">
                    <div className='sub-header'>Our mission</div>
                    <div className='description'>
                    At Rise, our mission is to swiftly bring advanced financial services to Egypt, ensuring accessibility and empowerment for all. Like the hummingbird gathering nectar effortlessly, we strive to simplify and accelerate your financial journey, making every transaction seamless and rewarding.
                    </div>
                </div>
            </Col>
            <Col xs={{ span: 24, order: 2 }} md={{ span: 11, order: 2 }}>
                <div className="blue-bird"></div>
            </Col>
            <Col xs={{ span: 24, order: 4 }} md={{ span: 11, order: 3 }}>
                <div className="woman-rise"></div>
            </Col>
            <Col xs={{ span: 24, order: 3 }} md={{ span: 13, order: 4 }}>
                <div className="our-story">
                    <div className='content'>
                        <div className='story'>Our story:</div>
                        <div className='sub-header'>Committed to excellence in emerging economies</div>
                        <div className='description'>
                            Rise was born from the vision of a team of seasoned 
                            professionals who share a belief in the potential of emerging economies like Egypt. With a steadfast commitment to uncompromising service quality, we set out to revolutionize financial accessibility and empower individuals across the region.
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
  );
};

export default AboutUs;
