import React, { FC, Fragment, useEffect, useState } from 'react'
import ChangeLanguageForm from '../../shared/components/ChangeLanguageForm'
import "./ambassadors.scss"
import logo from "./../../assets/images/logo_h.svg"
import slash from "./../../assets/images/slash.svg"
import ambasaddors from "./../../assets/images/Ambassadors.svg"
import { useTranslation } from 'react-i18next'
import ButtonComponent from '../../shared/components/ButtonComponent'
import applyStepImage from "./../../assets/images/apply.png"
import joinStepImage from "./../../assets/images/join.png"
import shareStepImage from "./../../assets/images/share.png"
import earnStepImage from "./../../assets/images/earn.png"
import mobileCredit from "./../../assets/images/mobile-credit.png"
import { AppRoutes } from '../../routes/routeConstants/appRoutes'
import { Col, Row, Spin } from 'antd'
import headerCollage from "./../../assets/images/header-collage.png"
import { getCookies } from '../../shared/utils/cookiesHelper'
import i18next from 'i18next'

const Ambassadors = () => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const language = getCookies().i18next || i18next.language
    const handleGoogleFormRedirection = () => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeMn_FXkUTs4b241xhKCDrHIppA5N5AUVqbh3O4qwdXIQl-SA/formrestricted")

    const applyButton = (
        <ButtonComponent loading={false} size="wide" onClick={handleGoogleFormRedirection} >
            {t("rise-ambassador-button-text")} {'>>'}
        </ButtonComponent>
    )

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 250)
    }, [language])

    return (
        <Fragment>
            {
                loading ?
                    <Spin /> :
                    <div className={`ambassadors ${language == "ar" && 'arabic'}`}>
                        <div className="ambassadors__header">
                            <div className="logo-container">
                                <img src={logo} alt="logo" className='logo' />
                                <img src={slash} alt="slash" className='slash' />
                                <img src={ambasaddors} alt="ambassadors-text" className='ambassadors-text' />
                            </div>
                            <ChangeLanguageForm color='black' />
                        </div>
                        <div className="ambassadors__header-content">
                            <h1>{t("rise-ambassador-header-text")}</h1>
                            <p>{t("rise-ambassador-header-subtext")}</p>

                            {window.innerWidth < 480 &&
                                <div className="header-collage-container">
                                    <img src={headerCollage} className="header-collage" />
                                </div>
                            }

                            {applyButton}
                        </div>

                        <Row gutter={[48, 0]} className="ambassadors__steps">
                            <Col span={24} sm={24} md={12} lg={6} className="step apply">
                                <img src={applyStepImage} alt="" />
                                <h3>{t("rise-ambassador-step-one-header")}</h3>
                                <p>{t("rise-ambassador-step-one-text")}</p>
                            </Col>
                            <Col span={24} sm={24} md={12} lg={6} className="step join">
                                <img src={joinStepImage} alt="" />
                                <h3>{t("rise-ambassador-step-two-header")}</h3>
                                <p>{t("rise-ambassador-step-two-text")}</p>
                            </Col>
                            <Col span={24} sm={24} md={12} lg={6} className="step share">
                                <img src={shareStepImage} alt="" />
                                <h3>{t("rise-ambassador-step-three-header")}</h3>
                                <p>{t("rise-ambassador-step-three-text")}</p>
                            </Col>
                            <Col span={24} sm={24} md={12} lg={6} className="step earn">
                                <img src={earnStepImage} alt="" />
                                <h3>{t("rise-ambassador-step-four-header")}</h3>
                                <p>{t("rise-ambassador-step-four-text")}</p>
                            </Col>
                        </Row>

                        <div className="ambassadors__banner">
                            <div className="ambassadors__banner-content">
                                <h3>
                                    {t("rise-ambassador-banner-text-1")} <br />
                                    {t("rise-ambassador-banner-text-2")} <span>{t("rise-ambassador-banner-text-3")}</span>
                                </h3>
                                <img src={mobileCredit} alt="" />
                            </div>
                        </div>

                        <div className="ambassadors__benefits">
                            <div className="header">
                                <h2> {t("rise-ambassador-benefits-header")} </h2>
                            </div>
                            <div className="benefit-list">
                                <ul>
                                    <li>{t("rise-ambassador-benefit-1")}</li>
                                    <li>{t("rise-ambassador-benefit-2")}</li>
                                    <li>{t("rise-ambassador-benefit-3")}</li>
                                </ul>
                                <ul>
                                    <li>{t("rise-ambassador-benefit-4")}</li>
                                    <li>{t("rise-ambassador-benefits-5")}</li>
                                    <li>{t("rise-ambassador-benefit-6")}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="apply-button">
                            {applyButton}
                        </div>

                        <div className="footer">
                            <div className="content">
                                <p> <span onClick={() => window.open(AppRoutes.PRIVACY_POLICY)}>{t("privacy")}</span> | <span onClick={() => window.open(AppRoutes.TERMS_AND_CONDITIONS)}>{t("terms_and_conditions")}</span> | {t("all_rights")}</p>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>
    )
}

export default Ambassadors