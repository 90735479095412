export const getOS = (): string => {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown OS";
  
    if (userAgent.indexOf("Win") !== -1) os = "Windows";
    if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
    if (userAgent.indexOf("X11") !== -1) os = "UNIX";
    if (userAgent.indexOf("Linux") !== -1) os = "Linux";
    if (userAgent.indexOf("Android") !== -1) os = "Android";
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) os = "iOS";
  
    return os;
  }

  export const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';
  
    if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    }
  
    return browserName
  };
  
  