import React, { FC } from 'react';
import varnaLogo from "../../../assets/images/partners/varna.png"
import concreteLogo from "../../../assets/images/partners/concrete.png"
import cortefielLogo from "../../../assets/images/partners/cortefiel.png"
import osamasalamaLogo from "../../../assets/images/partners/osamasalama.png"
import sistersandcoLogo from "../../../assets/images/partners/sistersandco.png"
import elshazlyLogo from "../../../assets/images/partners/elshazly.png"
import dlingerieLogo from "../../../assets/images/partners/dlingerie.png"
import guruLogo from "../../../assets/images/partners/guru.png"
import springfieldLogo from "../../../assets/images/partners/springfield.png"
import tomaLogo from "../../../assets/images/partners/toma.png"
import tomatoLogo from "../../../assets/images/partners/tomato.png"
import candcoLogo from "../../../assets/images/partners/candco.png"
import beverlyhillspoloclubLogo from "../../../assets/images/partners/beverlyhillspoloclub.png"
import birkenstockLogo from "../../../assets/images/partners/birkenstock.png"
import barakaLogo from "../../../assets/images/partners/baraka.png"
import newbalanceLogo from "../../../assets/images/partners/newbalance.png"
import nikeLogo from "../../../assets/images/partners/nike.png"
import peopleLogo from "../../../assets/images/partners/people.png"
import './partners.scss';
import { getBrowserInfo, getOS } from '../../../shared/utils/getOSDetail';

const Partners: FC = () => {

const isMobile = window.innerWidth < 480

const images = [
    nikeLogo,
    newbalanceLogo,
    barakaLogo,
    beverlyhillspoloclubLogo,
    birkenstockLogo,
    candcoLogo,
    concreteLogo,
    cortefielLogo,
    varnaLogo,
    dlingerieLogo,
    elshazlyLogo,
    guruLogo,
    peopleLogo,
    osamasalamaLogo,
    springfieldLogo,
    sistersandcoLogo,
    tomaLogo,
    tomatoLogo
]

const os = getOS();
const broswer = getBrowserInfo();
const macSafari = os === "MacOS" && broswer === "Safari"
const iphone = os === "iOS"

const renderPartnerContainerGroup = (imagesData: string[]) => (
    <div className="partner-container">
        {Array((macSafari || iphone) ? 1 : 2).fill(1).map(()=>(
            <div className={`partner-container__group ${(!macSafari && !iphone) && 'align'}`}>
                {imagesData?.map((image) => <img className='image' src={image} alt="" />)}
            </div>
        ))}
    </div>
)

return (
    <div id='partners' className="partners">
        <p className="label">
        Our partners
        </p>
        {renderPartnerContainerGroup(images)}
    </div>
);
};

export default Partners;
