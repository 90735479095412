import React, { FC, useEffect, useRef } from 'react'
import GeneratePDF from './GeneratePDF'
import ReactGA from 'react-ga4'
import "./legaldocument.scss";

interface LegalDocumentProps { }

const LegalDocument: FC<LegalDocumentProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    ReactGA.send({ hitType: "pageview", page: "/legal", title: "Legal Document" });

    return (
        <div className='legaldocument'>
            <GeneratePDF ref={componentRef}/>
        </div>
    )
}

export default LegalDocument