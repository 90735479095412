import React, { useEffect, useState } from 'react';
import './navbar.scss';
import logo from "../../../assets/images/logo-with-hummingbird.svg"
import ChangeLanguageForm from '../ChangeLanguageForm';
import ButtonComponent from '../ButtonComponent';
import { oneLink, openAppStore } from '../../utils/openApppStore';

interface NavbarProps {
  sections: Array<{ id: string; label: string }>;
  footer?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ sections, footer }) => {
  const [activeSection, setActiveSection] = useState<string>();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) observer.observe(element);
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) observer.unobserve(element);
      });
    };
  }, [sections]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, targetId: string) => {
    event.preventDefault();
    setActiveSection(targetId);
    const target = document.getElementById(targetId);
    const navbarHeight = document.querySelector('.navbar')?.clientHeight || 0;
    const faqOffset = targetId === 'faq' ? 100 : 0;

    if (target) {
        const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = targetPosition - navbarHeight - faqOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
};
  return (
    <nav className={`navbar ${footer && 'footer'}`}>
      <img src={logo} alt="Logo" className="logo" />
      {sections.map(section => (
        <a
          key={section.id}
          href={`#${section.id}`}
          onClick={(e) => handleClick(e, section.id)}
          className={`section ${footer && 'footer'} ${section.id === activeSection ? 'active' : ''}`}
        >
          {section.label}
        </a>
      ))}
      {!footer &&
        <>
          {/* TODO: Needed when translation is available */}
          {/* <ChangeLanguageForm color='black' /> */}
          <ButtonComponent size='small' onClick={oneLink}>Download app</ButtonComponent>
        </>
      }
    </nav>
  );
};

export default Navbar;
