import { Collapse } from 'antd';
import React, { FC } from 'react'
import "./faqcomponent.scss"

const { Panel } = Collapse;

interface FaqProps{
    question: string;
    answer: string;
}

interface FaqComponentProps { 
    faqData: FaqProps[]
}

const FaqComponent: FC<FaqComponentProps> = ({ faqData }) => {

    return (
        <Collapse accordion className="faq">
        {faqData.map((item, index) => (
          <Panel header={item.question} key={index + 1}>
            <p>{item.answer}</p>
          </Panel>
        ))}
      </Collapse>
    )
}

export default FaqComponent