import React, { FC } from 'react';
import ellipse from "../../../assets/images/ellipse.png"
import './metricsCardComponent.scss';

interface MetricsCardComponentProps{
    count?: string;
    label?: string;
    imgSrc?: string;
}

const MetricsCardComponent: FC<MetricsCardComponentProps> = ({ count, label, imgSrc }) => {
  return (
    <div className="metrics-card-component">
        <div className='icon-circle'>
            <img className='image-style' src={ellipse} alt="" />
            <img src={imgSrc} className='icon-style' alt="" />
        </div>
        <div className='metric'>
            <div className='value'>{count}</div>
            <div className='label'>{label}</div>
        </div>
    </div>
  );
};

export default MetricsCardComponent;
