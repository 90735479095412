import React, { useState } from 'react';
import './mobileNavBar.scss';
import logo from "../../../assets/images/logo-with-hummingbird.svg";
import ChangeLanguageForm from '../ChangeLanguageForm';
import ButtonComponent from '../ButtonComponent';
import { openAppStore } from '../../utils/openApppStore';
import menuIcon from "../../../assets/images/menu-icon.png";
import closeIcon from "../../../assets/images/close-icon.png";

interface NavbarProps {
  sections: Array<{ id: string; label: string }>;
}

const MobileNavbar: React.FC<NavbarProps> = ({ sections }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, targetId: string) => {
    event.preventDefault();
    const target = document.getElementById(targetId);
    const navbarHeight = document.querySelector('.mobile-navbar')?.clientHeight || 0;
    const faqOffset = targetId === 'faq' ? 100 : 0;

    if (target) {
      const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - navbarHeight - faqOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
      setIsMobileMenuOpen(false); // Close modal on section click
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="mobile-navbar">
      <img src={logo} alt="Logo" className="logo" />
      <div className='action-buttons'>
        {/* TODO: Needed when translation is available */}
        {/* <div><ChangeLanguageForm color='black' /></div> */}
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <img src={isMobileMenuOpen ? closeIcon : menuIcon} alt="action-icon" />
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="mobile-menu">
          {sections.map(section => (
            <a
              key={section.id}
              href={`#${section.id}`}
              onClick={(e) => handleClick(e, section.id)}
              className="mobile-menu-item"
            >
              {section.label}
            </a>
          ))}
        </div>
      )}
    </nav>
  );
};

export default MobileNavbar;
