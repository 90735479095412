import React, { Fragment, useEffect, useState } from 'react'
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import AppLogo from "./../../assets/images/logo_h.svg";
import HummingBird from "./../../assets/images/capa_1.svg"
import BackArrow from "./../../assets/images/arrow-back.svg"
import i18n from "i18next";
import "./privacyPolicy.scss"
import { useNavigate } from 'react-router-dom';
import ChangeLanguageForm from '../../shared/components/ChangeLanguageForm';
import { getCookies } from '../../shared/utils/cookiesHelper';
import i18next from 'i18next';

export const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const language = getCookies().i18next || i18next.language
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 250);
    }, [language])

    return (
        <div className={`privacy-policy ${language == "ar" ? 'align_right' : ''}`}>
            {
                loading ?
                    <Spin /> :
                    <Fragment>
                        <div className="privacy-policy--header">
                            {/* TODO needed  reference */}
                            {/* <img src={BackArrow} alt="" className='back-arrow' onClick={() => navigate(-1)} /> */}
                            <div className="logo-container">
                                <img src={HummingBird} alt="" className='humming-bird' />
                                <img src={AppLogo} alt="app logo" className='app-logo' />
                            </div>
                            <ChangeLanguageForm color='blue' />
                        </div>
                        <div className='privacy-policy--body'>
                            {
                                <Fragment>
                                    <h1 className='main-header'>{t('privacy_policy')}</h1>

                                    <h2 className="terms-service-header">
                                        {t('privacy')}
                                    </h2>
                                    <p className="terms-service-sub-header">
                                        {t('privacy_last_update')}
                                    </p>
                                    {/* TODO Loop through similar keys to render */}
                                    <p className="text">
                                        {t('privacy_1')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_2')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_3')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_4')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_5')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_6')}
                                    </p>
                                    <p className="text">
                                        {t('privacy_7')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('collecting')}
                                    </h3>
                                    <p className="text">
                                        {t('collecting_1')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_2')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_3')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_4')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_5')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_6')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_15')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_7')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_8')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_9')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_10')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_11')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_12')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_13')}
                                    </p>
                                    <p className="text">
                                        {t('collecting_14')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('using')}
                                    </h3>
                                    <p className="text">
                                        {t('using_1')}
                                    </p>
                                    <p className="text">
                                        {t('using_2')}
                                    </p>
                                    <p className="text">
                                        {t('using_3')}
                                    </p>
                                    <p className="text">
                                        {t('using_4')}
                                    </p>
                                    <p className="text">
                                        {t('using_5')}
                                    </p>
                                    <p className="text">
                                        {t('using_6')}
                                    </p>
                                    <p className="text">
                                        {t('using_7')}
                                    </p>
                                    <p className="text">
                                        {t('using_8')}
                                    </p>
                                    <p className="text">
                                        {t('using_9')}
                                    </p>
                                    <p className="text">
                                        {t('using_10')}
                                    </p>
                                    <p className="text">
                                        {t('using_11')}
                                    </p>
                                    <p className="text">
                                        {t('using_12')}
                                    </p>
                                    <p className="text">
                                        {t('using_13')}
                                    </p>
                                    <p className="text">
                                        {t('using_14')}
                                    </p>
                                    <p className="text">
                                        {t('using_15')}
                                    </p>
                                    <p className="text">
                                        {t('using_16')}
                                    </p>
                                    <p className="text">
                                        {t('using_17')}
                                    </p>
                                    <p className="text">
                                        {t('using_18')}
                                    </p>
                                    <p className="text">
                                        {t('using_19')}
                                    </p>
                                    <p className="text">
                                        {t('using_20')}
                                    </p>
                                    <p className="text">
                                        {t('using_21')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('sms')}
                                    </h3>
                                    <p className="text">
                                        {t('sms_1')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t("how")}
                                    </h3>
                                    <p className="text">
                                        {t("how_1")}
                                    </p>
                                    <p className="text">
                                        {t("how_2")}
                                    </p>
                                    <p className="text">
                                        {t("how_3")}
                                    </p>
                                    <p className="text">
                                        {t("how_4")}
                                    </p>
                                    <p className="text">
                                        {t("how_5")}
                                    </p>
                                    <p className="text">
                                        {t("how_6")}
                                    </p>
                                    <p className="text">
                                        {t("how_7")}
                                    </p>
                                    <p className="text">
                                        {t("how_8")}
                                    </p>
                                    <p className="text">
                                        {t("how_9")}
                                    </p>
                                    <p className="text">
                                        {t("how_10")}
                                    </p>
                                    <p className="text">
                                        {t("how_11")}
                                    </p>
                                    <p className="text">
                                        {t("how_12")}
                                    </p>
                                    <p className="text">
                                        {t("how_13")}
                                    </p>
                                    <p className="text">
                                        {t("how_14")}
                                    </p>
                                    <p className="text">
                                        {t("how_15")}
                                    </p>
                                    <p className="text">
                                        {t("how_16")}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('_consent')}
                                    </h3>
                                    <p className="text">
                                        {t('_consent_1')}
                                    </p>
                                    <p className="text">
                                        {t('_consent_2')}
                                    </p>
                                    <p className="text">
                                        {t('_consent_3')}
                                    </p>
                                    <p className="text">
                                        {t('_consent_4')}
                                    </p>
                                    <p className="text">
                                        {t('_consent_5')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t("marketing")}
                                    </h3>
                                    <p className="text">
                                        {t("marketing_1")}
                                    </p>
                                    <p className="text">
                                        {t("marketing_2")}
                                    </p>
                                    <p className="text">
                                        {t("marketing_3")}
                                    </p>
                                    <p className="text">
                                        {t("marketing_4")}
                                    </p>
                                    <p className="text">
                                        {t("marketing_5")}
                                    </p>
                                    <p className="text">
                                        {t("marketing_6")}
                                    </p>


                                    <h3 className="sub-header">
                                        {t("controlling")}
                                    </h3>
                                    <p className="text">
                                        {t("controlling_1")}
                                    </p>


                                    <h3 className="sub-header">
                                        {t("cookies")}
                                    </h3>
                                    <p className="text">
                                        {t("cookies_1")}
                                    </p>
                                    <p className="text">
                                        {t("cookies_2")}
                                    </p>
                                    <p className="text">
                                        {t("cookies_3")}
                                    </p>
                                    <p className="text">
                                        {t("cookies_4")}
                                    </p>
                                    <p className="text">
                                        {t("cookies_5")}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('ads')}
                                    </h3>
                                    <p className="text">
                                        {t('ads_1')}
                                    </p>
                                    <p className="text">
                                        {t('ads_2')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('protect')}
                                    </h3>
                                    <p className="text">
                                        {t('protect_1')}
                                    </p>
                                    <p className="text">
                                        {t('protect_2')}
                                    </p>
                                    <p className="text">
                                        {t('protect_3')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('childrens')}
                                    </h3>
                                    <p className="text">
                                        {t('childrens_1')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('accessing')}
                                    </h3>
                                    <p className="text">
                                        {t('accessing_1')}
                                    </p>
                                    <p className="text">
                                        {t('accessing_2')}
                                    </p>
                                    <p className="text">
                                        {t('accessing_3')}
                                    </p>
                                    <p className="text">
                                        {t('accessing_4')}
                                    </p>


                                    <h3 className="sub-header">
                                        {t("resolving")}
                                    </h3>
                                    <p className="text">
                                        {t("resolving_1")}
                                    </p>
                                    <p className="text">
                                        {t("resolving_2")}
                                    </p>


                                    <h3 className="sub-header">
                                        {t('definitions')}
                                    </h3>
                                    <p className="text">
                                        {t('definitions_1')}
                                    </p>
                                    <p className="text">
                                        {t('definitions_2')}
                                    </p>
                                    <p className="text">
                                        {t('definitions_3')}
                                    </p>
                                    <p className="text">
                                        {t('definitions_4')}
                                    </p>
                                </Fragment>
                            }
                        </div>
                    </Fragment>
            }
        </div>
    )
}
