import { Row, Col } from 'antd';
import React, { FC } from 'react';
import ButtonComponent from '../../../shared/components/ButtonComponent';
import manRise from "../../../assets/images/man-rise-hero.png"
import starOutline from "../../../assets/images/rating-star-outline.svg"
import './home.scss';
import { oneLink, openAppStore } from '../../../shared/utils/openApppStore';

const Home: FC = () => {

  return (
    <div id='home' className="home">
      <Row className='banner'>
        <Col xs={24} sm={24} md={14} lg={14} className="layout-left">
          <div className='finance'>Financial freedom, </div>
            <div className='simplified'>simplified for ALL.</div>
            <div className='description'>
              Tired of getting rejected for credit, long waiting times, hidden fees, or tons of paperwork? Rise gives you the freedom and flexibility to get access to modern financial services that will help you achieve a better future. Simplify your life and unlock new possibilities. 
            </div>
            <ButtonComponent size={'fixed'} onClick={oneLink}>Download app</ButtonComponent>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} className="layout-right" />
      </Row>
    </div>
  );
};

export default Home;
