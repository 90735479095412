import React, { FC } from 'react'
import moneyIcon from "../../../assets/images/money-icon.svg"
import walletIcon from "../../../assets/images/wallet-icon.svg"
import poundIcon from "../../../assets/images/pound-icon.svg"
import flashIcon from "../../../assets/images/flash-icon.svg"
import logo from "../../../assets/images/humming-bird.svg"
import logoRight from "../../../assets/images/humming-bird-right.svg"

import "./whatisrise.scss"
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { getCookies } from '../../../shared/utils/cookiesHelper'

interface WhatIsRiseProps { }

const WhatIsRise: FC<WhatIsRiseProps> = (props) => {

    const { t } = useTranslation();
    const language = getCookies().i18next || i18next.language;
    const isArabic = language == "ar" && 'arabic';

    const steps = [
        {
            icon: moneyIcon,
            title: `${t('no_bank_account')}`
        },
        {
            icon: walletIcon,
            title: `${t('no_credit_card')}`
        },
        {
            icon: poundIcon,
            title: `${t('no_hidden_fees')}`
        },
        {
            icon: flashIcon,
            title: `${t('quick_easy_apply')}`
        },
    ]

    return (
        <div className={`whatisrise ${isArabic}`}>
            <div className='cardheader'>
                <div className='title'>{t('what_is_rise')}</div>
                <div><img className='bird' src={isArabic ? logoRight : logo} /></div>
            </div>
            <div className='content'>
                <div className='description'>
                    {t('rise_description')}
                </div>
                <div className='steps'>
                    {steps.map(step => (
                        <div className='step'>
                            <span className='bg'>
                                <span className='icons'><img src={step.icon} /></span>
                            </span>
                            <span className='content'>{step.title}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhatIsRise