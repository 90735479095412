import { Collapse } from 'antd';
import React, { FC } from 'react';
import './faq.scss'; // Ensure you import the scss file
import questionMark from '../../../assets/images/questions.png';
import phones from '../../../assets/images/footer-phones.png';
import playstore from "../../../assets/images/google-play.svg"
import appstore from "../../../assets/images/app-store.svg"
import { openAppStore, openGooglePlaystore } from '../../../shared/utils/openApppStore';
import { getOS } from '../../../shared/utils/getOSDetail';

const { Panel } = Collapse;

const faqData = [
  {
    question: "How does Rise work?",
    answer: "Rise makes it easy for you to shop now and pay later. Here’s how it works today - sign up to Rise and get your purchasing power. Visit our partner stores to shop. When you’re ready to pay, open the Rise app and show the cashier your purchase QR code. Split your payment into installments and pay over time."
  },
  {
    question: "How can I unlock more purchasing power?",
    answer: "You can increase your buying power by completing payment for one (or more) transaction. We also ask you to provide us with more information about you to help us understand you better. Then just apply for a higher status."
  },
  {
    question: "Is my personal and financial information safe when using Rise?",
    answer: "We are committed to keeping your personal and financial information private and secure. Your information is confidential and will not be shared with anyone."
  },
  {
    question: "Can I use Rise to make multiple purchases?",
    answer: "Absolutely! You can make more than one purchase at a time using Rise up to your purchasing power limit. When using all your purchasing power, you can unlock more purchasing power by paying back at least one installment."
  },
  {
    question: "Is there a processing fee involved in making a purchase with Rise?",
    answer: "Rise applies a one-time fee for each purchase. This fee gets added to your purchase total and is divided into equal installments."
  }
];
const FAQ: FC = () => {
  const os = getOS();
  const isMobile = window.innerWidth < 480

  return (
    <div className="got-questions" id="faq">
      <div className="background">
        <img src={questionMark} alt="Question Mark" className="question-mark" />
        <div className="header">Got questions?</div>
        <div className="description">
        Curious about Rise? Here, you'll find all the essential info about how the app works and what it offers. We've got straightforward answers to your questions right here.</div>
      </div>

      <Collapse accordion className="faq">
      {faqData.map((item, index) => (
        <Panel header={item.question} key={index + 1}>
          <p>{item.answer}</p>
        </Panel>
      ))}
    </Collapse>
      {!isMobile && 
      <div className='download-rise-banner'>
        <img src={phones} className="align-image" alt="" />
        <div className='download-description'>
            <div className='header'>Download Rise now</div>
            <div className='sub-header'>Get your Rise app now form the app store, join thousands of Egyptians shopping with Rise and start shopping for the things you love today</div>
            <div className='download-icons'>
              {(os === 'Android' || os === 'Windows') && <img src={playstore} onClick={openGooglePlaystore} alt="google-play-icon" className='google-play' />}
              {(os === 'MacOS' || os === 'iOS') && <img src={appstore} onClick={openAppStore} alt="app-store-icon" />}
            </div>
        </div>
      </div>}
      {isMobile && 
      <div className='download-rise-banner'>
        <div className='download-description'>
            <div className='header'>Download Rise now</div>
            <div className='sub-header'>Get your Rise app now form the app store, join thousands of Egyptians shopping with Rise and start shopping for the things you love today</div>
            <div className='download-icons'>
            {(os === 'Android' || os === 'Windows') && <img src={playstore} onClick={openGooglePlaystore} alt="google-play-icon" className='google-play' />}
            {(os === 'MacOS' || os === 'iOS') && <img src={appstore} onClick={openAppStore} alt="app-store-icon" />}
          </div>
        </div>
        <img src={phones} className="align-image" alt="" />
      </div>}
    </div>
  );
};

export default FAQ;
