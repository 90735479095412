import React, { Fragment, useEffect, useState } from 'react'
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import AppLogo from "./../../assets/images/logo_h.svg";
import HummingBird from "./../../assets/images/capa_1.svg"
import BackArrow from "./../../assets/images/arrow-back.svg"
import "./termsAndConditions.scss"
import { useNavigate } from 'react-router-dom';
import ChangeLanguageForm from '../../shared/components/ChangeLanguageForm';
import { getCookies } from '../../shared/utils/cookiesHelper';
import i18next from 'i18next';

export const TermsAndConditions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const language = getCookies().i18next || i18next.language
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 250);
    }, [language])

    return (
        <div className={`terms-and-conditions ${language === "ar" ? 'align_right' : ''}`}>
            {
                loading ?
                    <Spin /> :
                    <Fragment>
                        <div className="terms-and-conditions--header">
                            {/* TODO needed reference */}
                            {/* <img src={BackArrow} alt="" className='back-arrow' onClick={() => navigate(-1)} /> */}
                            <div className="logo-container">
                                <img src={HummingBird} alt="" className='humming-bird' />
                                <img src={AppLogo} alt="app logo" className='app-logo' />
                            </div>
                            <ChangeLanguageForm color='blue' />
                        </div>
                        <div className='terms-and-conditions--body'>
                            {
                                <Fragment>
                                    <h1 className='main-header'>{t('tc_main_header')}</h1>

                                    <h2 className="terms-service-header">
                                        {t('tc_sub_header')}
                                    </h2>
                                    <p className="terms-service-sub-header">
                                        {t('tc_last_update')}
                                    </p>

                                    {/* Intro */}
                                    <p className="text">
                                        {t('tc_intro_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_intro_2')}
                                    </p>

                                    {/* Parties */}
                                    <h3 className="sub-header">
                                        {t('tc_parties')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_parties_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_services')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_services_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_eligibility')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_eligibility_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_eligibility_2')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_changes')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_changes_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_your_rise_account')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_your_rise_account_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_your_rise_account_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_your_rise_account_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_your_rise_account_4')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_verification_and_privacy')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_verification_and_privacy_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_verification_and_privacy_2')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_prohibited_activities')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_prohibited_activities_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_4')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_5')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_6')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_7')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_8')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_9')}
                                    </p>
                                    <p className="text">
                                        {t('tc_prohibited_activities_10')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_intellectual_property')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_intellectual_property_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_trademark_notices')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_trademark_notices_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_consent_to_electronic_communication')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_consent_to_electronic_communication_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_consent_to_electronic_communication_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_consent_to_electronic_communication_3')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_sms')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_sms_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_sms_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_sms_3')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_data_usage_consent')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_data_usage_consent_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_dispute_resolution')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_dispute_resolution_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_dispute_resolution_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_dispute_resolution_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_dispute_resolution_4')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_applicable_law_and_jurisdiction')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_applicable_law_and_jurisdiction_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_limitation_of_liability')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_limitation_of_liability_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_indemnification')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_indemnification_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_disclaimer_of_warranties')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_disclaimer_of_warranties_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_disclaimer_of_warranties_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_disclaimer_of_warranties_3')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_miscellaneous')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_miscellaneous_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_miscellaneous_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_miscellaneous_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_miscellaneous_4')}
                                    </p>
                                    <p className="text">
                                        {t('tc_miscellaneous_5')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_questions')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_questions_1')}
                                    </p>

                                    {/* Installment agreement */}
                                    <h2 className="terms-service-header">
                                        {t('tc_installment_agreement_main_header')}
                                    </h2>
                                    <p className="terms-service-sub-header">
                                        {t('tc_installment_agreement_last_updated')}
                                    </p>

                                    <p className="text">
                                        {t('tc_installment_agreement_intro_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_4')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_5')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_6')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_7')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_8')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agreement_intro_9')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_installment_payments')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_installment_payments_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_payments_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_payments_3')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_late_payments')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_late_payments_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_delinquency_and_default')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_delinquency_and_default_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_approval_and_cancellation')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_approval_and_cancellation_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_approval_and_cancellation_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_approval_and_cancellation_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_approval_and_cancellation_4')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_pre_authorizations')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_pre_authorizations_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_4')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_5')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_6')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_7')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_8')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_9')}
                                    </p>
                                    <p className="text">
                                        {t('tc_pre_authorizations_10')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_refunds')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_refunds_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_transfers')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_transfers_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_complaints_and_disputes')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_complaints_and_disputes_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_dispute_resolution')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_installment_agree_dispute_resolution_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agree_dispute_resolution_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agree_dispute_resolution_3')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_applicable_law_and_jurisdiction')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_installment_agree_applicable_law_and_jurisdiction_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_no_warranties')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_no_warranties_1')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_express_written_consent')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_express_written_consent_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_express_written_consent_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_express_written_consent_3')}
                                    </p>
                                    <p className="text">
                                        {t('tc_express_written_consent_4')}
                                    </p>
                                    <p className="text">
                                        {t('tc_express_written_consent_5')}
                                    </p>
                                    
                                    <h3 className="sub-header">
                                        {t('tc_miscellaneous')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_installment_agree_miscellaneous_1')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agree_miscellaneous_2')}
                                    </p>
                                    <p className="text">
                                        {t('tc_installment_agree_miscellaneous_3')}
                                    </p>

                                    <h3 className="sub-header">
                                        {t('tc_electronic_transactions')}
                                    </h3>
                                    <p className="text">
                                        {t('tc_electronic_transactions_1')}
                                    </p>
                                    
                                </Fragment>
                            }
                        </div>
                    </Fragment>
            }
        </div>
    )
}
