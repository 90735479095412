import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { ErrorMessage, Field } from "formik";
import React, { FC, Fragment } from "react";
import Error from "../Error";
import "./dropdownfield.scss";
import downLogo from "./../../../assets/images/CaretDown.svg"
import downLogoBlue from "./../../../assets/images/CaretDownBlue.png"

interface DropdownFieldProps {
  name: string;
  value: any;
  label: string;
  onChange: (value: any, record: any) => void;
  className?: string;
  placeholder: string;
  options: DefaultOptionType[] | undefined;
  disabled?: boolean;
  color: "blue"|"black"
}

const DropdownField: FC<DropdownFieldProps> = (props) => {
  const { name, value, label, onChange, className, placeholder, options, disabled, color } = props;
  const location = window.location.pathname.split('/')[1]

  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors, setTouched }, meta }: any) => (
          <div
            className={`dropdown-field ${meta?.error && meta.touched && "error"} ${className}`}
          >
            {label && <label>{label}</label>}
            <Select
              {...field}
              placeholder={placeholder}
              onChange={(value, record) => {
                onChange(value, record)
              }}
              data-testid={name}
              value={value}
              options={options}
              suffixIcon={<img src={color === "blue" ? downLogoBlue : downLogo} />}
              onBlur={() => setTouched({ ...touched, [name]: true })}
              disabled={disabled}
            />
          </div>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message: string) =>
          <Error
            testId={`input-error-${name}`}
            message={message}
          />
        }
      </ErrorMessage>
    </Fragment>
  );
};

export default DropdownField;
