import React, { FC } from 'react'
import BannerImageComponent from '../../shared/components/BannerImageComponent'
import HeaderComponent from '../../shared/components/HeaderComponent'
import SamsungFAQ from './SamsungFAQ'
import "./samsunglandingpage.scss"
import StepsForPurchase from './StepsForPurchase'
import WhatIsRise from './WhatIsRise'

interface SamsungLandingPageProps { }

const SamsungLandingPage: FC<SamsungLandingPageProps> = () => {
    return (
        <div className="samsunglandingpage">
            <HeaderComponent />
            <BannerImageComponent/>
            <StepsForPurchase />
            <WhatIsRise />
            <SamsungFAQ />
        </div>
    )
}

export default SamsungLandingPage