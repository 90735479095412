import i18next from 'i18next';
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ButtonComponent from '../../../shared/components/ButtonComponent';
import FaqComponent from '../../../shared/components/FaqComponent';
import { getCookies } from '../../../shared/utils/cookiesHelper';
import { getDeviceType } from '../../../shared/utils/getWindowSize';
import { fbOneLink, instaOneLink, openGooglePlaystore } from '../../../shared/utils/openApppStore';
import "./samsungfaq.scss"

interface SamsungFAQProps { }

const SamsungFAQ: FC<SamsungFAQProps> = (props) => {

    const deviceType = getDeviceType();
    const isTabletOrDesktop = deviceType === 'desktop' || deviceType === 'tablet';
    const isMobile = deviceType === 'mobile'
    const { t } = useTranslation();
    const language = getCookies().i18next || i18next.language;
    const isArabic = language == "ar" && 'arabic';

    const faqData = [
        {
          question: `${t('how_to_make_payments')}`,
          answer: `${t('make_payments_description')}`,
        },
        {
          question: `${t('when_to_pay')}`,
          answer: `${t('when_to_pay_answer')}`,
        },
        {
          question: `${t('pay_fees_question')}`,
          answer: `${t('pay_fees_answer')}`,
        },
        {
          question: `${t('need_bank_account_question')}`,
          answer: `${t('need_bank_account_answer')}`,
        },
        {
          question: `${t('late_payment_question')}`,
          answer: `${t('late_payment_answer')}`,
        },
        {
          question: `${t('where_to_buy_question')}`,
          answer: `${t('where_to_buy_answer')}`,
        },
      ]

      const location = useLocation();
      const isFB = location.pathname.includes('samsung-fb')
      const isInsta = location.pathname.includes('samsung-insta')

    return (
        <div className={`samsungfaq ${isArabic}`}>
            <FaqComponent faqData={faqData} />
            <div className={`mobile-download ${deviceType !== 'mobile' ? 'big-screen' : ''} `}>
                <ButtonComponent size={`${isMobile ? 'wide' : deviceType === 'tablet' ? 'tablet' : 'desktop'}`} onClick={isFB ? fbOneLink : isInsta ? instaOneLink : fbOneLink}>{t('download_now')}</ButtonComponent>
            </div>
            <div className='privacy'>
            {t('privacy_policy_reserved')}
            </div>
        </div>
    )
}

export default SamsungFAQ