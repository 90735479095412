import React, { FC } from "react";
import { Carousel } from "antd";
import 'antd/lib/carousel/style/index.css';
import Hanoushaa from "../../../assets/images/hanoushaa.png"
import Taufiq from "../../../assets/images/FeloTawfiq.png"
import Mounir from "../../../assets/images/Mounirr.png"
import Nadine from "../../../assets/images/NadineWaleed.png"
import feedback1 from "../../../assets/images/hanoushaa.svg"
import feedback2  from "../../../assets/images/text.svg"
import feedback3  from "../../../assets/images/nadine.svg"
import feedback4  from "../../../assets/images/tawfiq.svg"
import startQuote from "../../../assets/images/start-quote.png"
import endQuote from "../../../assets/images/end-quote.png"

const CarouselComponent: FC = () => {
    const testimonials = [
      {
        image: Mounir,
        header: feedback2,
        name: 'Mounir',
      },
      {
        image: Nadine,
        header: feedback3,
        name: 'Nadine Waleed',
      },
      {
        image: Taufiq,
        header: feedback4,
        name: 'Felo Tawfiq',
      },
      {
        image: Hanoushaa,
        header: feedback1,
        name: 'Hanoushaa',
      }
    ];
    return (
      <Carousel autoplay autoplaySpeed={3000} arrows={true} slidesPerRow={1} slidesToScroll={1} className="custom-carousel">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="carousel-slide">
            <div className="testimonial-container">
              <div><img src={testimonial.image} alt={testimonial.name} className="user-image" /></div>
              <div className="feedback-container">
                <div className="feedback-header">
                    <img src={testimonial.header} alt="quotes" className="feedback" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </Carousel>
    )
}

export default CarouselComponent;