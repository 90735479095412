import React, { FC } from 'react';
import downloadApp from "../../../assets/images/downloadApp.png"
import applyRise from "../../../assets/images/applyRise.png"
import purchaseImage from "../../../assets/images/PurchaseImage.png"
import payBack from "../../../assets/images/PayImage.png"
import risePay from "../../../assets/images/rise-pay.png"
import easyPayments from "../../../assets/images/payments.png"
import pushNotification from "../../../assets/images/push-notification.png"
import risePayMobile from "../../../assets/images/risePayMobile.png"
import easyPaymentsMobile from "../../../assets/images/easyPaymentsMobile.png"
import pushNotificationMobile from "../../../assets/images/pushNotification.png"
import './howItWorks.scss';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';


const HowItWorks: FC = () => {
  const isMobile = window.innerWidth < 480
  return (
    <div id='how-it-works' className="how-it-works">
        <div className='header'>How Rise works</div>
        <div className='sub-header'>Download the app, apply, get approved for purchasing power, and shop at partner stores. Pay back monthly in your preferred way—it's that easy!</div>
        <div className='steps'>
            <div className='image-container'>
                <img className='icon-style' src={downloadApp} alt="downloadApp" />
                <div className='text'>Download app</div>
            </div>
            <div className='image-container'>
                <img className='icon-style' src={applyRise} alt="applyRise" />
                <div className='text'>Apply for a Rise account</div>
            </div>
            <div className='image-container'>
                <img className='icon-style' src={purchaseImage} alt="purchase" />
                <div className='text'>Purchase at partner stores</div>
            </div>
            <div className='image-container'>
                <img className='icon-style' src={payBack} alt="payBack" />
                <div className='text'>Pay back easily</div>
            </div>
        </div>
        <div className='content first'>
            <div className='container'>
                <div className='header'>RisePay</div>
                <div className='sub-header'>Introducing RisePay: instant QR payment with purchasing power up to 6K EGP</div>
                <div className='description'>Experience the convenience of RisePay—a feature that allows you to make quick payment using QR with your purchasing power up to 6,000 EGP. Seamlessly integrated into your shopping experience to make purchases with ease.</div>
            </div>
            <div><img className='image-style' src={risePayMobile} /></div>
        </div>
        <div className='content-right'>
        <div><img className='image-style' src={easyPaymentsMobile} /></div>
            <div className='container'>
                <div className='header'>Easy payments</div>
                <div className='description'>You can conveniently repay all your shopping with Rise using your preferred method: credit/debit card, eWallet, or even cash. Choose the option that suits you best and enjoy seamless transactions every time.</div>
            </div>
        </div>
        <div className='content'>
            <div className='container'>
                <div className='header'>Stay updated with push </div>
                <div className='description'>Stay up-to-date with Rise's push notifications, ensuring you never miss important updates or opportunities. Keep informed effortlessly, directly from your device.</div>
            </div>
            <div><img className='image-style' src={pushNotificationMobile} /></div>
        </div>
        <div className='privacy-security'>
           <div className='security-content'>
                <div className='header'>Security and Privacy:</div>
                <div className='sub-header'>Our Highest Standards</div>
                <div className='description'>
                At Rise, we uphold the highest standards of security and privacy to safeguard your financial information and transactions. We employ state-of-the-art encryption technology and rigorous security protocols to ensure that your data remains protected at all times. Our commitment to privacy means that your personal information is handled with the utmost care and confidentiality. Rest assured, with Rise, your financial security is our top priority.
                </div>
                <div className='footer-text' onClick={() => window.open(AppRoutes.TERMS_AND_CONDITIONS)}>Full T&C document {'>'}</div>
                <div className='footer-text' onClick={() => window.open(AppRoutes.PRIVACY_POLICY)}>See privacy {'>'}</div>
           </div>
        </div>
    </div>
  );
};

export default HowItWorks;
